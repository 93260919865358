main {
  display: flex;
  flex-direction: column;
  padding-bottom: 47px;
  box-sizing: border-box;
  min-height: calc(100vh - 240px);
}

.topPart {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 440px;
}

.topPartBackground {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}

.topPartContent {
  padding: 160px 20px 48px;
  box-sizing: border-box;
  z-index: 2;
  height: 100%;
}

.topPartHeader {
  font-size: 56px;
  font-family: 'Poppins';
  font-weight: 400;
  max-width: 960px;
  text-align: center;
  color: #fff;
  margin: 40px 0 0 0;
}

.topPartHeaderHighlighted {
  color: #BC935D;
}

.content {
  width: 100%;
  max-width: 1440px;
  align-self: center;
  padding: 0 140px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .topPart {
    height: 250px;
  }

  .topPartContent {
    display: flex;
    align-items: center;
    padding: 104px 16px 40px;
  }
  .topPartHeader {
    font-size: 32px;
    margin: 0;
  }
  .content {
    padding: 0 16px;
  }
}