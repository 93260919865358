.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 240px;
  background-color: #0B254A;
}

.footerContainer {
  max-width: 1440px;
  padding: 0 140px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footerText {
  color: #fff;
  margin: 0 0 16px 0;  
}

.footerText > a {
  color: #BC935D;
  text-decoration: none;
}

.footerSocial > a {
  margin-right: 8px;
}

.footerSocial > a:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 800px) {
  .footerContainer {
    padding: 0 16px;
  }
}

@media screen and (max-width: 450px) {
  .footerContainer {
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
  }

  .footerLeft {
    margin-bottom: 20px;
  }

  .footerRight {
    align-items: center;
  }
}