main {
  display: flex;
  flex-direction: column;
  padding-bottom: 47px;
  box-sizing: border-box;
  min-height: calc(100vh - 240px);
}

.topPart {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 440px;
}

.topPartBackground {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}

.topPartContent {
  padding-top: 160px;
  padding-bottom: 48px;
  box-sizing: border-box;
  z-index: 2;
}

.topPartHeader {
  font-size: 56px;
  font-family: 'Poppins';
  font-weight: 400;
  max-width: 960px;
  text-align: center;
  color: #fff;
}

.topPartHeaderAdditional {
  font-size: 24px;
  font-weight: 400;
  max-width: 960px;
  text-align: center;
  color: #fff;
}

.topPartHeaderHighlighted {
  color: #BC935D;
}

.topPartHeaderHighlighted > a {
  color: inherit;
  text-decoration: none;
}

.linkBack {
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  color: #fff;
  text-decoration: none;
}

.linkBackText {
  margin: 0 0 0 7px;
}

.content {
  width: 100%;
  max-width: 1440px;
  align-self: center;
  padding: 0 140px;
  box-sizing: border-box;
}

.topPartTotalPrice {
  font-size: 32px;
  color: #fff;
}

.topPartTotalPriceHighlighted {
  color: #BC935D;
}


.order {
  margin-top: 152px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  align-self: center;
  padding: 0 140px;
  box-sizing: border-box;
}

.orderContent {
  width: 100%;
  box-sizing: border-box;
  background-color: #071B38;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 0;
  color: #fff;
}

.orderForm {
  width: 100%;
}

.orderFormHeader {
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 30px 0;
}

.orderFormHeader > h3 {
  margin: 0;
}

.orderFormBodyRow {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.orderFormInput {
  height: 40px;
  width: 500px;
}

.orderFormCheckbox {
  margin-right: 10px;
}

.orderFormBodyRowH4 {
  margin: 0;
  font-size: 16px;
}

.orderFormBodyRowText {
  margin: 0;
}

.orderButton {
  height: 40px;
  background-color: #BC935D;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 10px;
}

@media screen and (max-width: 800px) {
  .order {
    padding: 0 16px;
  }

  .content {
    padding: 0 16px;
  }
}