.services {
  opacity: 0;
  transition: opacity .3s;
}

.service {
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, .2);
  border-radius: 8px;
  padding: 24px;
  margin: 10px 0;
  box-sizing: border-box;
}

.serviceContentClosed {
  width: 100%;
  display: flex;
}

.serviceContentClosed > h2 {
  font-size: 24px;
  font-weight: 500;
  color: #BC935D;
}

.serviceOpenButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  margin-right: 17px;
  padding: 0;
  cursor: pointer;
}

.serviceBuyButtonWrapper {
  display: flex;
}

.serviceBuyButton {
  width: 64px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #BC935D;
  color: #fff;
  font-weight: normal;
  margin-left: 17px;
  padding: 0;
  cursor: pointer;
}

.serviceTitle {
  font-size: 24px;
  font-weight: 500;
  color: #BC935D;
  margin: 0;
}

.servicePrice {
  display: flex;
  justify-content: end;
  align-items: center;
  min-width: fit-content;
  width: 100%;
}

.serviceCartCount {
  font-size: 24px;
  font-weight: 500;
  color: #BC935D;
  margin: 0 0 0 17px; 
}

.servicePriceText {
  font-size: 24px;
  font-weight: 500;
  color: #BC935D;
  margin: 0;
}

.serviceTitleContainer {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 890px;
  margin-right: 20px;
  cursor: pointer;
}

.serviceContentOpened {
  /* box-shadow: none; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.serviceCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none;
  background-color: transparent;
  width: 16px;
  height: 16px;
  margin-right: 17px;
  padding: 0;
  cursor: pointer;
}

.serviceTopContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 33px);
}

.serviceCloseIcon {
  transform: rotate(180deg);
}

.serviceBottomContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: rgba(255, 255, 255, .2) solid 1px;
  padding-top: 20px;
  margin-top: 20px;
}

.serviceBottomLeftContent {
  display: flex;
  flex-direction: column;

}

.serviceOption {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.serviceOptionTextWrapper {
  display: flex;
  align-items: center;
}

.serviceOptionText {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
  margin-top: 3px;
}

.serviceAdditionalText {
  font-size: 12px;
}

.servicePlusIcon {
  font-size: 24px;
  color: #BC935D;
  margin-right: 8px;
}

.serviceBottomRightContent {
  max-width: 536px;
}

.serviceDescription {
  text-align: end;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: rgba(255, 255, 255, .7);
  margin: 0;
}

@media screen and (max-width: 1080px) {
  .serviceBottomContent {
    flex-direction: column;
  }

  .serviceDescription {
    text-align: start;
  }
}

@media screen and (max-width: 800px) {
  .serviceContentClosed {
    flex-wrap: wrap;
  }

  .serviceTitleContainer {
    margin-right: 0;
    width: calc(100% - 38px);
  }

  .serviceTitle {
    font-size: 18px;
  }

  .servicePrice {
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    padding-top: 20px;
  }

  .servicePriceText {
    font-size: 18px;
  }

  .serviceBuyButton {
    height: 32px;
  }
}