.s-grid-block {
  display: flex;
  flex-direction: column;
  will-change: width, height, transform;
}

.s-grid-block_visible {
  position: absolute;
  z-index: 10;
  -webkit-transform: translate3d(
    0,
    0,
    0
  ); /* DO NOT REMOVE. Fixes animation bug in Safari */
}

.s-grid-block_closed {
  /* opacity: 1 !important; */
}

.s-grid-block_ref {
  position: relative;
  /* margin: 24px 0; */
}

.s-grid-block_ref-opened {
  width: 100%;
}

.s-grid-block_ref-closed {
  width: 100%;
}

.s-grid-block_visible > .s-grid-block__inside {
  /* width: auto; */
}

/* .s-grid-block_ref > .s-grid-block__inside_closed {
  width: 468px;
}

.s-grid-block_ref > .s-grid-block__inside_opened {
  width: 100%;
} */

.s-grid-block__inside {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end;
  align-self: flex-end; */
  /* height: 392px; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  /* background: linear-gradient(139.23deg, #eef0f5 4.98%, #e6e9ef 94.88%); */
  /* border-radius: 11.8421px; */
  /* transition: box-shadow .3s; */
  /* margin: 18px 12px; */
  /* padding: 24px; */
  /* background: linear-gradient(
    180deg,
    rgba(36, 97, 186, 0.19) 0%,
    rgba(7, 27, 56, 0) 42.25%
  ); */
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  /* filter: drop-shadow(0px 4px 250px rgba(0, 0, 0, 0.25)); */
  /* border-radius: 8px; */
}

.s-grid-block__inside_closed {
  /* box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8); */
  /* animation: blockShadowFadeOut 0.15s; */
  /* cursor: pointer; */
  /* animation-timing-function: ease-in-out; */
}

.s-grid-block__inside_closed:hover {
  /* box-shadow: 9.92105px 9.92105px 14.8421px rgba(166, 180, 200, 0.7),
    -11.10526px -11.10526px 14.8421px rgba(255, 255, 255, 0.8); */
}

.s-grid-block__inside_closed:active {
  /* box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75); */
  /* animation: blockShadowFadeIn 0.15s; */
  /* animation-timing-function: ease-in-out; */
}

.s-grid-block__inside_opened {
  /* box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75); */
  /* transition: box-shadow 0.3s; */
}

.s-grid-block__inside_opened:hover {
  /* box-shadow: inset 9px 9px 13px rgba(166, 180, 200, 0.75),
      inset -9px -9px 18px rgba(255, 255, 255, 0.75); */
}

.s-grid-block__inside_opened:active {
  /* box-shadow: inset 13px 13px 18px rgba(166, 180, 200, 0.75),
      inset -13px -13px 23px rgba(255, 255, 255, 0.75); */
}

.s-grid-block__content {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  -webkit-transform: translate3d(
    0,
    0,
    0
  ); /* DO NOT REMOVE. Fixes animation bug in Safari */
}

@media screen and (max-width: 1070px) {
}

@media screen and (max-width: 800px) {
  .s-grid-block_ref {
    width: 100%;
  }

  .s-grid-block_ref > .s-grid-block__inside {
    width: 100%;
  }
}

@keyframes blockShadowFadeIn {
  0% {
    /* box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8); */
  }
  50% {
    box-shadow: none;
  }
  100% {
    /* box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75); */
  }
}

@keyframes blockShadowFadeOut {
  0% {
    /* box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75); */
  }
  50% {
    box-shadow: none;
  }
  100% {
    /* box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8); */
  }
}
