.header {
  width: 100%;
  height: 152px;
  position: absolute;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  transition: height .3s, background-color .3s;
}

.header_fixed {
  height: 80px;
  position: fixed;
  background-color: rgb(7, 27, 56);
  transition: height .3s, background-color .3s;
}

.nav {
  max-width: 1440px;
  padding: 0 140px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.navLink {
  position: relative;
  text-decoration: none;
}

.cartItemsCount {
  position: absolute;
  top: -15px;
  left: 12px;
  background-color: #BC935D;
  border-radius: 20px;
  padding: 0 8px;
  color: #fff;
  font-size: 12px;
}


@media screen and (max-width: 800px) {
  .header {
    height: 80px;
  }
  
  .nav {
    padding: 0 16px;
  }
}